import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
//import { bindable } from 'aurelia';
import { Viewtimeoffrequestcomponent } from '../viewtimeoffrequest/viewtimeoffrequestcomponent';
import * as XLSX from 'xlsx';
// import {config} from '../../../shared/services/config';
import { data } from 'jquery';
import {config} from "../../../shared/services/config";
import Scrollbar from "smooth-scrollbar";
import { constants } from '../../../shared/services/constants';
import {SharedState} from "../../../shared/state/sharedstate";
import {Router} from 'aurelia-router';
import {LoginService} from "../../../shared/services/loginservice";


@inject(DialogController,JwtService,LeavemanagementService,DialogService,SharedState,Router,LoginService)
export class HolidayListComponent{
     isLoading = false;
    getHolidayList=[];
    pageSize = config.grid_PazeSize;
    FavoritesExist = false;
    sitemapId=constants.holidaylistsitemapid;
   
    constructor(controller,JwtService,LeavemanagementService,DialogService,sharedState,router,loginservice){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.locid = this.jwtService.getLocationId();
        this.leavemanagementService = LeavemanagementService;
        this.sharedState = sharedState;
        this.router = router;
        this.loginservice = loginservice;

    }
    configimgurl=config.img_url;

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.mdi-calendar-clock');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.mdi-calendar-clock .sidebar-sub .holiday-list a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached(){
        this.getCurrentRouteURL();
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        this.leavemanagementService.LocationsForDropdown()
        .then(data => {
            this.locations= data;
        });
        this.leavemanagementService.YearsForDropdown()
        .then(data => {
            this.Years= data;
        });
        const d = new Date();
        this.year = d.getFullYear();
        const selectedYear = this.year;
        const ID = this.locid;
        const timeoff = {
            LocationId:this.locid,
            Year:d.getFullYear(),
            EmployeeID:this.empid,
            };

        this.leavemanagementService.GeHolidaysList(timeoff).then(data=>
                {
                    this.getHolidayList  =data;
                });
        this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapId)
            .then(data=>{
            this.FavoritesExist=data;
            });
        this.addToRecentItems();
    }
    nameLength(row) {
        return row.name.length;
    }
    locationMatcher = (a, b) => {
        return a.key === b.key;
    };
    yearMatcher = (a, b) => {
        return a.Year === b.year;
    };
    SearchHoliday() {
        const d = new Date();
        this.year = d.getFullYear();
        this.locid = this.jwtService.getLocationId();
        let ID = this.locid;
        let selectedYear = this.year;
        if (this.Location) {
            ID = this.Location.LocationId;
        }
        if (this.Year) {
            selectedYear = this.Year.Year;
        }
        const timeoff = {
        LocationId:ID,
        Year:selectedYear,
        EmployeeID:this.empid,
        };
        this.leavemanagementService.GeEmployeeHolidays(timeoff).then(data=>
            {
                this.getHolidayList  =data;
            });
    }
    exportData() {
        const data = this.getHolidayList;
        const filename = 'holidaylist';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const requiredColumns = ['S.No', 'Holiday name', 'Date','Weekday'];
        const filteredData = data.map(holiday => {
        return {
          'SNo': holiday.SNo,
          'Holiday name':  holiday.HolidayName,
          'Date' :  holiday.StartDayText,
          'Weekday':  holiday.Weekday
        };
      });
        // const worksheet = XLSX.utils.json_to_sheet(data); // show all columns in Excel sheet.
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    addToFavourites(){
       this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
        {
          window.location.reload();
        });
    }
    addToRecentItems(){
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
         {

         });
     }
     Reset() {
        this.attached();
    }
}
