import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { Viewtimeoffrequestcomponent } from '../viewtimeoffrequest/viewtimeoffrequestcomponent';
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import {ApproveTimeOffComponent} from "../approvetimeoff/approvetimeoffcomponent";
import { constants } from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
@inject(DialogController,JwtService,LeavemanagementService,DialogService,Router,LoginService)
export class Employeetimeoffadjustmentcomponent{
    pageSize = config.grid_PazeSize;
    searchTerm = '';
    search = '';
    showTable = false;
    FromDate = null;
    ToDate = null;
    isLoading = false;
   showEmployeeDropdown = false;
   FavoritesExist = false;
    Successmessages=false;
   sitemapId=constants.mytimeoffdetailssitemapid;
    statusOptions = [
        { label: 'All', value: 'All', checked: false },
        { label: 'Pending approval', value: 'ACT', checked: false },
        { label: 'Cancelled', value: 'INA', checked: false },
        { label: 'Approved', value: 'APP', checked: false },
        { label: 'Taken', value: 'Taken', checked: false },
    ];

    constructor(controller,JwtService,LeavemanagementService,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
    }
    activate(viewData){
       this.EmployeeId = viewData.EmployeeId;
       this.leaveTypeId = viewData.LeaveTypeID;
        console.log(viewData);
    }
    attached(){
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        } 
        this.leavemanagementService.YearsForDropdown()
        .then(data => {
            this.Years= data;
        });

        const d = new Date();
        this.year = d.getFullYear();
        const selectedYear = this.year;
        
        const timeoff= {
            EmployeeID:this.EmployeeId,
            LeaveTypeId:this.leaveTypeId,
            Year:d.getFullYear()
            // From:this.From,
            // To:this.To
        };
        console.log(timeoff);
        this.leavemanagementService.GetPersonalLeaveDetails(timeoff).then(data=>
            {
                console.log("HII");
                this.filteredEmployeesSummery=data.LeaveApplicationList;
                this.isLoading = false;
                console.log(this.filteredEmployeesSummery);
            });
       
    }

    yearMatcher = (a, b) => {
        return a.Year === b.year;
    };

    Search() {
        const d = new Date();
        this.year = d.getFullYear();
        let selectedYear = this.year;
        console.log(selectedYear);
        console.log(this.year);
        if (this.Year) {
            selectedYear = this.Year.Year;
            console.log(this.Year.Year);
        }
        console.log(selectedYear); 
        const timeoff = {
        EmployeeID:this.EmployeeId,
        LeaveTypeId:this.leaveTypeId,
        Year:selectedYear
       
        };
        this.leavemanagementService.GetPersonalLeaveDetails(timeoff).then(data=>
            {
                this.filteredEmployeesSummery=data.LeaveApplicationList;
            });
    }

    cancel(){
        this.controller.cancel();
    }
    Reset() {
        this.attached();
    }

    resetStatusDropdown() {
        this.statusOptions.forEach((status, index) => {
            if (index === 1 || index === 2) {
                status.checked = true; // Select 'Pending approval' and 'Pending with me'
            } else {
                status.checked = false; // Deselect other statuses
            }
        });

        // Update the selectedStatusList if needed
        this.selectedStatusList = this.statusOptions.filter(status => status.checked);

        //console.log(this.selectedStatusList);
    }
}
