import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import {SharedState} from '../../../shared/state/sharedstate';
import {config} from '../../../shared/services/config';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { DialogService } from 'aurelia-dialog';
import { LeavemanagementService } from '../../../shared/services/leavemanagementservice';
import { FeedbackService } from '../../../shared/services/feedbackservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {HeaderService} from "../../../shared/services/headerservice";
import * as XLSX from 'xlsx';
import { constants } from '../../../shared/services/constants';
import {LoginService} from "../../../shared/services/loginservice";
import { ViewEmployeeTimeOffDetailsComponent } from '../viewemployeetimeoffdetails/viewemployeetimeoffdetailscomponent';

@inject(SharedState,LeavemanagementService,FeedbackService,JwtService, Router, HttpClient,  ValidationControllerFactory,DialogService,HeaderService,LoginService,Element)
export class Appreciation {

    options = []; //sendto array
    selected = {}; //selected employees in send to
    searchText = ''; //search text in send to dropdown
    selectAll = true; //select all in send to dropdown
    departments = []; // departments array
    errors = null;
    selectedSentoEmployees = [];
    SelectedSendtoEmployeesIDs = "";
    EmployeeListIDs = [];
    showPopup = false;
    years = [];
    showDropdown = false;
    selectedEmployees = '';
    showEmpDropdown = false;
    selectedStatus = 'ACT';

    searchDepartment = '';
    selectedDepartmentNames = [];
    selectedDepartmentNamesIDs = "";
    showDepartmentDropdown = false;
    selectAllDep = true;
    selecteddep = {};
    FavoritesExist = false;
    isLoading = false;
    Timeoffsummary=[];
    pageSize=5;

    EmployeeStatus="";
    InstantApprovaldetails=[];
    TimeOffSummaryList=[];
    sitemapId=constants.employeetimeoffsummarysitemapid;

    constructor(sharedState,LeavemanagementService,feedbackservice,JwtService, router, httpClient,controllerFactory,dialogService,headerService,loginservice,element) {

        this.sharedState = sharedState;
        this.leavemanagementService = LeavemanagementService;
        this.feedbackservice = feedbackservice;
        this.jwtService= JwtService;
        // this.searchQuery = '';
        this.dropdownOptions = [];
        this.selectedEmployees = [];
        this.selectedSentoEmployees = [];
        this.selectedEmployee = null;
        this.filterEmpOption = '';
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.dialogService = dialogService;
        this.controller = controllerFactory.createForCurrentScope();
        this.empid = this.jwtService.getEmployeeId();
        this.headerservice = headerService;
        this.loginservice = loginservice;
        this.selectedDepartmentNames = [];
    }

    async attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }

        window.addEventListener('click', (event) => {
            if (!event.target.closest('.Deparmentdropdown-menu') && !event.target.closest('.sendtoDepartment')) {
                this.closeDepartmentDropdown();
            }
        });

        window.addEventListener('click', (event) => {
            if (!event.target.closest('.dropdown-menu') && !event.target.closest('.sendto')) {
                this.closeDropdown();
            }
        });

        this.headerservice.dynamicHeader(this.empid)
            .then(data => {
               // this.departmentId = data.DepartmentId;
               this.departmentId = -2;
                this.updateSelectedDepartment();

                const timeoffData = {
                    strYear: this.year,
                    EmployeeStatus: this.selectedStatus,
                    strDepartmentID: this.departmentId,
                    LoggedInEmployeeId : this.empid,
                };
                this.isLoading = true;
                this.leavemanagementService.GetEmployeeTimeOffSummery(timeoffData)
                    .then(data => {
                        this.Timeoffsummary=data;
                        this.empid = this.jwtService.getEmployeeId();

                        // Group data by employee ID
                        const groupedData = {};
                        for (const item of data) {

                            let employeeName = item.EmployeeName;
                            let empCode=item.EmpCodeDeptClass;
                            let additionalData = '';

                            // Check if the employeeName contains '()' and extract the additional data
                            const lastParenthesesIndex = employeeName.lastIndexOf('(');
                            if (lastParenthesesIndex !== -1) {
                                additionalData = employeeName.substring(lastParenthesesIndex + 1, employeeName.length - 1).trim();
                                employeeName = employeeName.substring(0, lastParenthesesIndex).trim();
                            }

                            // Remove '-' symbols from the employeeName
                            employeeName = employeeName.replace(/-/g, '').trim();

                            if (!groupedData[employeeName,empCode]) {
                                groupedData[employeeName,empCode] = {
                                    EmployeeName: employeeName,
                                    EmployeeCode:empCode,
                                    AdditionalData: additionalData, // Add the additional data to the groupedData
                                    TimeOffSummery: [],
                                };
                            }
                            groupedData[employeeName,empCode].TimeOffSummery.push(item);
                            this.isLoading = false;
                        }

                        // Assign grouped data to a new property
                        this.groupedEmployeeTimeOffSummery = Object.values(groupedData)
                        // Filter out items with matching EmployeeId
                            .map(group => ({
                                ...group,
                                TimeOffSummery: group.TimeOffSummery.filter(item => item.EmployeeId !== parseInt(this.empid))
                            }));

                    });


            });

        this.DefaultDepartmentid();

        this.leavemanagementService.DepartmentDropdown(this.empid, this.selectedStatus)
            .then(data => {
                this.departments = data;
                this.updateSelectedDepartment();
            });
        this.updateSelectedDepartment();




        this.leavemanagementService.YearsForDropdown()
            .then(data => {
                this.years= data;

            });
        const d = new Date();
        this.year = d.getFullYear();
        const selectedYear = this.year;

        await  this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapId)
            .then(data1=>{
                this.FavoritesExist=data1;
            });

        this.addToRecentItems();

        this.addToRecentItems();


    }

    DefaultDepartmentid(){
        this.headerservice.dynamicHeader(this.empid)
            .then(data => {
                this.defaultdepartmentId = -2;// data.DepartmentId;
                console.log(this.defaultdepartmentId);
                this.updateSelectedDepartment();
                return this.defaultdepartmentId;
            });
    }


    updateSelectedDepartment() {
        if (this.departments.some(department => department.DepartmentId === this.defaultdepartmentId)) {
            this.selectedDepartmentNamesIDs = [this.defaultdepartmentId.toString()];
            this.selectedDepartmentNames = this.departments
                .filter(department => department.DepartmentId === this.defaultdepartmentId)
                .map(department => department.Name);

            // Check the selected departments in the checkbox
            this.departments.forEach(department => {
                this.selecteddep[department.DepartmentId] = department.DepartmentId === this.defaultdepartmentId;
            });

        }
        this.leavemanagementService.GetAllEmployeeNamesDropdown(this.empid, this.selectedStatus, this.defaultdepartmentId)
            .then(data => {
                this.options = data;
            });
    }



    yearMatcher = (a, b) => {

        return a.Year === b.year;

    };


    get filteredDepartments() {
        const filtered = this.departments.filter(Department =>
            Department.Name.toLowerCase().includes(this.searchDepartment.toLowerCase())
        );
        // Check if dropdownOptions is empty
        if (filtered.length === 0 && this.searchDepartment.trim().length > 0) {
            const errorInfo = {
                error: {
                    message: "No records found"
                }
            };
            this.searchDepartmentError = [errorInfo];
        } else {
            this.searchDepartmentError = []; // Clear any previous error messages
        }

        return filtered;
    }
    hasSearchDepartmentError() {
        return this.searchDepartmentError.length > 0 && this.searchDepartmentError[0].error.message === "No records found";
    }

    selectDepartment(checked, Department) {
        this.selecteddep[Department.DepartmentId] = checked;
        this.selectedDepartments = this.departments.filter(Department => this.selecteddep[Department.DepartmentId]);

        if (!this.selectedDepartmentNamesIDs) {
            this.selectedDepartmentNamesIDs = [];
        }

        if (checked) {
            this.selectedDepartmentNamesIDs.push(Department.DepartmentId.toString());
            this.selectedDepartmentNames.push(Department.Name);
        } else {
            const index = this.selectedDepartmentNamesIDs.indexOf(Department.DepartmentId.toString());
            if (index > -1) {
                this.selectedDepartmentNamesIDs.splice(index, 1);
            }
            const nameIndex = this.selectedDepartmentNames.indexOf(Department.Name);
            if (nameIndex > -1) {
                this.selectedDepartmentNames.splice(nameIndex, 1);
            }
        }

        this.leavemanagementService.GetAllEmployeeNamesDropdown(this.empid, this.selectedStatus, this.selectedDepartmentNamesIDs)
            .then(data => {
                this.options = data;
            });
    }


    isselecteddep(Department) {
        return this.selecteddep[Department.DepartmentId];
    }


    selectAllDepartments() {
        // Toggle the selected state for all options
        Object.keys(this.selecteddep).forEach(Department => {
            this.selecteddep[Department] = this.selectAllDep;
        });

        // Update the selectedDepartmentNames array
        if (this.selectAllDep) {
            let filterdepartments =  this.departments ;
            filterdepartments = filterdepartments.filter(department => department.DepartmentId !=-2);
            this.selectedDepartmentNames = filterdepartments.map(Department => Department.Name);
            this.selectedDepartmentNamesIDs = filterdepartments.map(Department => Department.DepartmentId );
            console.log(this.selectedDepartmentNamesIDs);

        } else {
            this.selectedDepartmentNames = [];
            this.selectedDepartmentNamesIDs = [];
        }

        // Update the selectedDepartments array
        this.selectedDepartments = this.departments.filter(Department => this.selecteddep[Department.DepartmentId]);

        this.selectAllDep = !this.selectAllDep;

        return this.leavemanagementService.GetAllEmployeeNamesDropdown(this.empid, this.selectedStatus, this.selectedDepartmentNamesIDs)
            .then(data => {
                this.options = data;
            });
    }


    toggleDepartmentDropdown() {
        this.showDepartmentDropdown = !this.showDepartmentDropdown;
    }

    closeDepartmentDropdown() {
        this.showDepartmentDropdown = false;
    }
    get filteredOptions() {
        const filtered = this.options.filter(option =>
            option.EmployeeName.toLowerCase().includes(this.searchText.toLowerCase())
        );
        // Check if dropdownOptions is empty
        if (filtered.length === 0 && this.searchText.trim().length > 0) {
            const errorInfo = {
                error: {
                    message: "No records found"
                }
            };
            this.searchTextError = [errorInfo];
        } else {
            this.searchTextError = []; // Clear any previous error messages
        }

        return filtered;
    }

    selectOption(checked, option) {
        this.selected[option.EmployeeID] = checked;
        this.selectedOptions = this.options.filter(option => this.selected[option.EmployeeID]);

        if (!this.SelectedSendtoEmployeesIDs) {
            this.SelectedSendtoEmployeesIDs = [];
        }

        if (checked) {
            this.SelectedSendtoEmployeesIDs.push(option.EmployeeID.toString());
            this.selectedSentoEmployees.push(option.EmployeeName);
        } else {
            const index = this.SelectedSendtoEmployeesIDs.indexOf(option.EmployeeID.toString());
            if (index > -1) {
                this.SelectedSendtoEmployeesIDs.splice(index, 1);
            }
            const nameIndex = this.selectedSentoEmployees.indexOf(option.EmployeeName);
            if (nameIndex > -1) {
                this.selectedSentoEmployees.splice(nameIndex, 1);
            }
        }
    }



    isSelected(option) {

        return this.selected[option];
    }
    selectAllOptions() {
        if (this.selectAll) {
            // Toggle the selected state for all options
            Object.keys(this.selected).forEach(option => this.selected[option] = this.selectAll);

            // Update the selectedOptions array with the currently selected options
            this.selectedSentoEmployees = this.options.map(option => option.EmployeeName);
            this.SelectedSendtoEmployeesIDs = this.options.map(option => option.EmployeeID);
        } else {
            // Clear the selected state for all options
            Object.keys(this.selected).forEach(option => this.selected[option] = this.selectAll);

            // Reset the selectedOptions and selectedSentoEmployees arrays
            this.selectedOptions = [];
            this.selectedSentoEmployees = [];
            this.SelectedSendtoEmployeesIDs = [];
        }

        this.selectAll = !this.selectAll;
    }

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;

    }

    handleClickOutsideDropdown = (event) => {
        const dropdownElement = document.querySelector('.dropdown-menu');
        const buttonElement = document.querySelector('.sendto');
        if (
            dropdownElement &&
            !dropdownElement.contains(event.target) &&
            !buttonElement.contains(event.target)
        ) {
            this.closeDropdown();
        }
    };

    closeDropdown = () => {
        // Method logic here
        this.showDropdown = false;
    };

    async displayEmployeess(event) {
        this.SelectedSendtoEmployeesIDs = []; // Clear the selected employee IDs
        this.selectedSentoEmployees = []; // Clear the selected employee names
        this.selectedDepartmentNames = [];// Clear the selected department names
        this.options = [];
        //this.selectAll = !this.selectAll;
        //this.selectAllDep = !this.selectAllDep;
        this.DefaultDepartmentid();
        Object.keys(this.selected).forEach(key => {
            this.selected[key] = false;
        });
        Object.keys(this.selecteddep).forEach(key => {
            this.selecteddep[key] = false;
        });

        const statusVal = event.target.value;
        this.selectAll = statusVal === "INA" || statusVal === "" || statusVal === "ACT";
        this.selectAllDep = this.selectAll;
        this.eName = '';
        this.showEmployeeDropdown = false;

        // const response = await this.leavemanagementService.GetAllEmployeeNamesDropdown(this.empid, statusVal, this.selectedDepartmentNamesIDs);
        // const GetAllEmployeesList = await response;
        //this.options = GetAllEmployeesList;
        //const filterOption = this.eName.toLowerCase();
        //this.filteredEmployees = GetAllEmployeesList.filter(empname => empname.EmployeeName.toLowerCase().includes(filterOption));

        this.leavemanagementService.DepartmentDropdown(this.empid, statusVal)
            .then(data => {
                this.departments = data;
            });
    }

    addToFavourites(){
        // this.sitemapId=150;

        this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
        {
            window.location.reload();
        });
    }

    addToRecentItems(){
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
        {
            // window.location.reload();

        });
    }
    getTotal(timeOffSummery, propertyName) {
        let total = 0;
        if (timeOffSummery && timeOffSummery.length > 0) {
            for (const item of timeOffSummery) {
                total += item[propertyName];
            }
        }
        return total;
    }

    Search(){
        this.isLoading = true;
        const selectedRadio = document.querySelector('input[name="employees"]:checked');
        this.selectedValue = this.selectedStatus; //selectedRadio ? selectedRadio.value : '';
        let selectedYear = this.year;

        // if(this.year)
        // {
        //     selectedYear = this.year;
        // }
        if (this.Year) {
            selectedYear = this.Year.Year;
        }

        const timeoffData = {
            strYear: selectedYear,
            EmployeeStatus: this.selectedValue,
            strDepartmentID: Array.isArray(this.selectedDepartmentNamesIDs) ? this.selectedDepartmentNamesIDs.join(',') : '',
            strEmployeeID: Array.isArray(this.SelectedSendtoEmployeesIDs) ? this.SelectedSendtoEmployeesIDs.join(',') : '',
            LoggedInEmployeeId : this.empid,
        };


        this.leavemanagementService.GetEmployeeTimeOffSummery(timeoffData)
            .then(data => {
                // Group data by employee ID
                const groupedData = {};
                this.Timeoffsummary=data;
                if (this.Timeoffsummary && this.Timeoffsummary.length > 0) {
                    for (const item of data) {
                        let employeeName = item.EmployeeName;
                        let empCode=item.EmpCodeDeptClass;
                        let additionalData = '';

                        // Check if the employeeName contains '()' and extract the additional data
                        const lastParenthesesIndex = employeeName.lastIndexOf('(');
                        if (lastParenthesesIndex !== -1) {
                            additionalData = employeeName.substring(lastParenthesesIndex + 1, employeeName.length - 1).trim();
                            employeeName = employeeName.substring(0, lastParenthesesIndex).trim();
                        }

                        // Remove '-' symbols from the employeeName
                        employeeName = employeeName.replace(/-/g, '').trim();

                        if (!groupedData[employeeName,empCode]) {
                            groupedData[employeeName,empCode] = {
                                EmployeeName: employeeName,
                                EmployeeCode:empCode,
                                AdditionalData: additionalData, // Add the additional data to the groupedData
                                TimeOffSummery: [],
                            };
                        }
                        groupedData[employeeName,empCode].TimeOffSummery.push(item);

                        this.isLoading = false;
                    }

                    // Assign grouped data to a new property
                    // this.groupedEmployeeTimeOffSummery = Object.values(groupedData);

                    // Assign grouped data to a new property
                    this.groupedEmployeeTimeOffSummery = Object.values(groupedData)
                    // Filter out items with matching EmployeeId
                        .map(group => ({
                            ...group,
                            TimeOffSummery: group.TimeOffSummery.filter(item => item.EmployeeId !== parseInt(this.empid))
                        }));
                    this.searchDepartmentError = [];
                    this.searchText = '';
                    this.searchDepartment = '';
                }
                else {
                    // No data found
                    this.groupedEmployeeTimeOffSummery = [];
                }
                // Turn off the loading indicator
                this.isLoading = false;
            });


    }


    Reset() {
        // Reset form data
        this.selectedStatus = 'ACT'; // Set the default radio button value

        // this.selectedDepartmentNamesIDs = []; // Clear the selected department IDs
        this.selectedDepartmentNames = []; // Clear the selected department names
        this.SelectedSendtoEmployeesIDs = []; // Clear the selected employee IDs
        this.selectedSentoEmployees = []; // Clear the selected employee names
        // Clear selected checkboxes in dropdowns
        Object.keys(this.selected).forEach(key => {
            this.selected[key] = false;
        });
        Object.keys(this.selecteddep).forEach(key => {
            this.selecteddep[key] = false;
        });
        Object.keys(this.selected).forEach(key => {
            this.selected[key] = false;
        });
        this.selectedOptions = [];
        // Check if selectAll is true and toggle it
        if (!this.selectAll) {
            this.selectAll = !this.selectAll;
        }
        if(!this.selectAllDep) {
            this.selectAllDep = !this.selectAllDep;
        }

        this.searchDepartmentError = [];
        this.searchText = '';
        this.searchDepartment = '';

        // ...any other properties you need to reset...

        // Clear the search results
        this.EmployeeTimeOffSummery = [];
        this.attached();
    }

    // EmpSummary(summary) {
    //   this.dialogService.open({
    //     viewModel:ViewEmployeeTimeOffSummaryPopUpComponent,
    //    // view: '../employeetimeoffadjustmentdetailes/employeetimeoffadjustmentcomponent.html',
    //     model: summary,
    //   });
    // }

    EmpSummary(value,leavedata) {
        const modelData = {
            headerText: value,
            leaveData: leavedata,

        };
        this.dialogService.open({viewModel: ViewEmployeeTimeOffDetailsComponent, model: modelData});
    }


    exportData() {
        const data = this.Timeoffsummary.filter(item => item.EmployeeId !== parseInt(this.empid));
        console.log(data);
        const filename = 'employeetimeoffsummery';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const headers = {
            Employeename: 'Employee name',
            TimeOfftype: 'Time off type',
            Timeoffcredits: 'Time off credits',
            Leavestaken: 'Time offs taken',
            Timeoffsscheduled: 'Time off(s) scheduled',
            Timeoffsbalance:'Time offs balance',
            Pendingapproval: 'Pending approval',
            Pendinglossofpaytimeoff:'Pending loss of pay time off',
            ApprovedlossofPay:'Approved loss of pay time off'

        };
        const filteredData = data.map(leave => {
            return {
                Employeename:leave.EmployeeName,
                TimeOfftype:leave.TimeOffType,
                Timeoffcredits:leave.TimeOffCredits,
                Leavestaken:leave.LeavesTaken,
                Timeoffsscheduled:leave.TimeOffsScheduled,
                Timeoffsbalance:leave.TimeOffsBalance,
                Pendingapproval: leave.PendingApproval,
                Pendinglossofpaytimeoff:leave.ApprovedLossOffPay,
                ApprovedlossofPay:leave.ApprovedLossOffPay
            };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(leave => Object.values(leave));
        const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }




}
